<script setup>
import { RouterLink } from 'vue-router'
import Logo from './assets/Logo.vue'
import axios from "axios";
import {ref} from "vue";
import LinkedinIcon from "@/components/assets/LinkedinIcon.vue";
import FuturaLogo from "@/components/assets/FuturaLogo.vue";

const baseURL = import.meta.env.VITE_URL

const state = ref([]);

axios.get(`${baseURL}/api/menus`).then(res => {
  state.value = res.data.data
})

</script>

<template>
  <div class="container-fluid">
    <footer class="row py-5 border-top">
      <div class="col-sm-auto col-12 offset-md-1 d-flex flex-column">
        <a href="/" class="d-flex align-items-md-center mb-3 link-body-emphasis text-decoration-none">
          <Logo />
        </a>
        <address class="text-secondary d-inline-block ms-md-4 ps-md-3 fw-light">FUTURA Vorsorge <br>
          Gass 2 <br>
          5242 Lupfig</address>

        <div class="d-flex flex-wrap align-items-end mt-sm-auto mt-5 ms-md-4 ps-md-3 ">
          <a href="https://futura.ch/" class="me-4">
            <futura-logo/>
          </a>

          <a href="https://www.linkedin.com/company/futura-vorsorge/">
            <LinkedinIcon />
          </a>
        </div>
      </div>
    </footer>

      <div class="row border-top">
        <div class="col d-flex flex-wrap offset-md-1 py-3">
          <router-link to="/impressum" class="fw-light text-secondary text-decoration-none">IMPRESSUM</router-link>
          <router-link class="fw-light text-secondary text-decoration-none ms-5" to="/datenschutzerklarung">DATENSCHUTZERKLÄRUNG</router-link>
        </div>
      </div>

  </div>
</template>

<style scoped>
address,
.router-link-exact-active.active,
.nav .nav-item .nav-link {
  color: var(--bs-secondary) !important;
  font-weight: 300;
}

</style>
